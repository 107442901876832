$(function(){
  var header_mobile = $(".flo-header-mobile");

  $(".flo-header-mobile").appendTo(".flo-block:first header");
  $(".flo-header-mobile").css("visibility", "visible");

  /* START: REMOVE ALL BUT FIRST */
    // $(".flo-header-mobile:not(:first)").remove();
  /* END: REMOVE ALL BUT FIRST */

  /* START: STICKY STYLE A */
    $(".flo-header-mobile").first().addClass("not-sticky");
    $(".flo-header-mobile.sticky.flo-header-mobile--sticky-style-a").first()
      .on("sticky-end", function(){
        $(this).addClass("not-sticky");
        setTimeout(function () {
          $(this).sticky("update");
        }, 900);
      })
      .on("sticky-start", function(){
        $(this).removeClass("not-sticky");
      })
      .sticky({
        zIndex: 1000,
        className: "is-sticky",
        wrapperClassName: "flo-header-mobile-sticky-wrapper"
      })
    ;
  /* END: STICKY STYLE A */

  /* START: STICKY STYLE B */
    if ($(".flo-header-mobile--sticky-style-b") && $(window).width() < 768) {
      var sticky_icon = $(".flo-header-mobile__sticky-icon");
      var sticky_icon_set_visibility = function() {
        if (
          $(document).scrollTop() > header_mobile.outerHeight(true)
        ) {
          sticky_icon.fadeIn("fast");
        } else {
          sticky_icon.fadeOut("fast");
        }
      };

      sticky_icon_set_visibility();

      $(document).on("scroll", function(){
        sticky_icon_set_visibility();
      });

    }
  /* END: STICKY STYLE B */

});
