window.flo_block_gallery_view_3 = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-block-gallery-view-3";
  var dotb = "." + b;
  var parent = $el.parents(".flo-block");
  var $b = $el.find(dotb);
  var navThumbs = '';

  if($el.find(dotb + "__thumbs").length){
    navThumbs = $el.find(dotb + "__thumbs");
  }

  $el.find(dotb + "__images")
    .slick({
      prevArrow: $el.find(dotb + "__arrow--prev"),
      nextArrow: $el.find(dotb + "__arrow--next"),
      asNavFor: navThumbs
    })
  ;
  if($el.find(dotb + "__thumbs").length){
    $el.find(dotb + "__thumbs")
      .slick({
        arrows: false,
        variableWidth: true,
        asNavFor: $el.find(dotb + "__images"),
        focusOnSelect: true
      })
    ;
  }
}
