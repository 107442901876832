window.flo_block_listing_3 = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-block-listing-3";
  var dotb = "." + b;
  var $b = $el.find(dotb);

  function do_masonry() {
    $b.masonry({
      columnWidth: dotb + '__grid-sizer',
      percentPosition: true
    });
  }
  if ($b.hasClass(b + "--is-masonry")) {
    do_masonry();
    $el.find("img").imagesLoaded(function(){
      setTimeout(function(){
        do_masonry();
      });
    })
  }
}
