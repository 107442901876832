window.flo_block_slideshow_2 = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-block-slideshow-2";
  var dotb = "." + b;
  var $b = $el.find(dotb);

  $el.find(dotb + "__slides")

    /* START: ARROWS */
      .on("init", function(){
        if ($el.find(dotb + "__slides .slick-slide:not(.slick-cloned)").length <= 1) {
          $el.find(dotb + "__arrow").remove();
        } else {
          $el.find(dotb + "__arrow--prev").click(function(){
            $el.find(dotb + "__slides").slick("slickPrev");
          });
          $el.find(dotb + "__arrow--next").click(function(){
            $el.find(dotb + "__slides").slick("slickNext");
          });
        }
      })
    /* END: ARROWS */

    /* START: HEADER AREA */
      .on("elementsColorLight", function(){
        $el.find(".flo-header__logo")
          .addClass("flo-header__logo--is-light")
        ;
        setTimeout(function(){
          $el.find(".flo-header-mobile__logo")
            .addClass("flo-header-mobile__logo--light")
          ;
        })
      })
      .on("elementsColorDark", function(){
        $el.find(".flo-header__logo")
          .removeClass("flo-header__logo--is-light")
        ;
        setTimeout(function(){
          $el.find(".flo-header-mobile__logo")
            .removeClass("flo-header-mobile__logo--light")
          ;
        })
      })
    /* END: HEADER AREA */

    /* START: LOGO */
      .on("elementsColorLight", function(){
        $el.find(dotb + "__logo")
          .addClass(b + "__logo--is-light")
        ;
      })
      .on("elementsColorDark", function(){
        $el.find(dotb + "__logo")
          .removeClass(b + "__logo--is-light")
        ;
      })
    /* END: LOGO */

    .trigger("floInit")
  ;

  /* START: SCROLL FOR MORE */
    $(dotb + "__scroll-down-area").on("click", function(){
      $('html, body').animate({
        scrollTop: $(window).height()
      }, 400);
    });
  /* END: SCROLL FOR MORE */

  /* START: MOBILE -> OVERFLOW BEHAVIOR */
    if ($(window).width() < 768) {
      var $content_wrap = $el.find(dotb + "__content-wrap");
      var $dotb_c = $el.find(dotb);
      var b_freeze_class = b + "--mobile-freeze";

      $($content_wrap).on("scroll", function(){
        // console.log(
        //   "Before Freeze:",
        //   $content_wrap.scrollTop() + $content_wrap.innerHeight(),
        //   $content_wrap[0].scrollHeight,
        //   $b.hasClass(b_freeze_class)
        // );

        if($content_wrap.scrollTop() + $content_wrap.innerHeight() >= $content_wrap[0].scrollHeight) {
          $b.addClass(b_freeze_class);
        }
      });

      $(document).on("scroll", function(){

        if ($b.hasClass(b_freeze_class)) {
          // console.log(
          //   "After Freeze:",
          //   $(document).scrollTop() + $(window).height(),
          //   $b.height() + $b.offset().top,
          //   $b.hasClass(b_freeze_class)
          // )

          if ($(document).scrollTop() + $(window).height() <= $b.height() + $b.offset().top) {
            $b.removeClass(b_freeze_class);
          }
        }
      });
    }
  /* END: MOBILE -> OVERFLOW BEHAVIOR */
  /* START: MENU - MOBILE - ADD DROPDOWN TOGGLES TO EVERY ITEM WITH DROPDOWN */
    if (window.innerWidth < 768) {
      $el.find(dotb + "__menu > li.menu-item-has-children")
        .children("a")
        .after("<div class='"+b+"__menu-dropdown-toggle'><i class='flo-icon flo-icon-close'></i></div>")
      ;

      $el.find(dotb + "__menu").on("click", dotb + "__menu-dropdown-toggle", function(e){
        e.preventDefault();
        console.log("Toggle Clicked");
        $(this).siblings(".sub-menu").slideToggle("slow");
        $(this).parent().toggleClass("children-visible");
      });
    }
  /* END: MENU - MOBILE - ADD DROPDOWN TOGGLES TO EVERY ITEM WITH DROPDOWN */

}
