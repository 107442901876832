window.flo_featured_links_4 = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-block-featured-links-4";
  var dotb = "." + b;
  var slider = $el.find(dotb + "__slider")

  /* START: FEATURED LINKS SLIDER */
  slider.slick({
    arrows: false,
    variableWidth: true,
    centerMode: false,
    slidesToShow: 3,
    responsive:
      [{
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          variableWidth: false
        }
      }]
  });
  /* END: FEATURED LINKS SLIDER */

  /* START: ARROWS */
  $el.find(dotb + "__arrow-left").click(function(){
    slider.slick('slickPrev');
  });

  $el.find(dotb + "__arrow-right").click(function(){
    slider.slick('slickNext');
  });
/* START: ARROWS */
}
