window.flo_block_gallery_view_1 = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-block-gallery-view-1";
  var dotb = "." + b;
  var parent = $el.parents(".flo-block");
  var $b = $el.find(dotb);

  $el.find(dotb + "__images").slick({
    variableWidth: true,
    arrows: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: "767",
        settings: {
          variableWidth: false,
          centerMode: false,
          adaptiveHeight: true
        }
      }
    ]
  });

  $el.find(dotb + "__arrow--prev").on("click", function(){
    $el.find(dotb + "__images").slick("slickPrev");
  });
  $el.find(dotb + "__arrow--next").on("click", function(){
    $el.find(dotb + "__images").slick("slickNext");
  });

  /* START: FOCUSED CLASS */
    $el.find(dotb + "__images").on("beforeChange click", function(){
      $b.addClass(b + "--is-focused");
    });

    $(window).on("scroll", function(){
      $b.removeClass(b + "--is-focused");
    })
  /* END: FOCUSED CLASS */
}
