window.flo_block_featured_slideshow_2 = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-block-featured-slideshow-2";
  var dotb = "." + b;
  var parent = $el.parents(".flo-block");
  var $b = $el.find(dotb);

  $el.find(dotb + "__slides").slick({
    variableWidth: true,
    nextArrow: $el.find(dotb + "__arrow--next"),
    prevArrow: $el.find(dotb + "__arrow--prev"),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          variableWidth: false
        }
      }
    ]
  });
}
