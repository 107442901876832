window.flo_block_slideshow_1 = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-block-slideshow-1";
  var dotb = "." + b;
  var $b = $el.find(dotb);

  $el.find(dotb + "__slides")

    /* START: ARROWS */
      .on("init", function(){
        if ($el.find(dotb + "__slides .slick-slide:not(.slick-cloned)").length <= 1) {
          $el.find(dotb + "__arrow").remove();
        } else {
          $el.find(dotb + "__arrow--prev").click(function(){
            $el.find(dotb + "__slides").slick("slickPrev");
          });
          $el.find(dotb + "__arrow--next").click(function(){
            $el.find(dotb + "__slides").slick("slickNext");
          });
        }
      })
    /* END: ARROWS */

    /* START: HEADER AREA */
      .on("elementsColorLight", function(){
        $el.find(".flo-header__logo")
          .addClass("flo-header__logo--is-light")
        ;
      })
      .on("elementsColorDark", function(){
        $el.find(".flo-header__logo")
          .removeClass("flo-header__logo--is-light")
        ;
      })
    /* END: HEADER AREA */

    /* START: LOGO */
      .on("elementsColorLight", function(){
        $el.find(dotb + "__logo")
          .addClass(b + "__logo--is-light")
        ;
        setTimeout(function(){
          $el.find('.flo-header-mobile__logo').addClass(b + "__logo--is-light");
        });
      })
      .on("elementsColorDark", function(){
        $el.find(dotb + "__logo")
          .removeClass(b + "__logo--is-light")
        ;
        setTimeout(function(){
          $el.find('.flo-header-mobile__logo').removeClass(b + "__logo--is-light");
        });
      })
    /* END: LOGO */

    /* START: MIDDLE AREA  */
      .on("init afterChange", function(){
        var current_slide = $(this).find(".slick-current");

        var new_title = current_slide.attr("data-title");
        var new_subtitle = current_slide.attr("data-subtitle");
        var new_url = current_slide.attr("data-url");
        var new_link_label = current_slide.attr("data-link-label");

        var title_area__title = $el.find(dotb + "__footer-middle-area-slide-title");
        var title_area__title_decorative_letter = $el.find(dotb + "__footer-middle-area-slide-title-decorative-letter");
        var title_area__subtitle = $el.find(dotb + "__footer-middle-area-slide-subtitle");
        var title_area__button = $el.find(dotb + "__footer-middle-area-slide-link");

        if (new_title.trim() !== "") {
          title_area__title.fadeIn().changeText(new_title, "slide_left", 800);
          title_area__title_decorative_letter.fadeIn().changeText(new_title[0], "slide_left", 800);
          title_area__subtitle.fadeIn().changeTextUI(new_subtitle, "fade", 800);
        } else {
          title_area__title.fadeOut();
          title_area__subtitle.fadeOut();
          title_area__title_decorative_letter.fadeOut();
        }

        if (new_url.trim() !== "" && new_link_label !== "") {
          title_area__button
            .removeClass("hidden")
            .addClass("visible")
            .attr("href", new_url)
            .text(new_link_label)
          ;
        } else {
          title_area__button
            .addClass("hidden")
            .removeClass("visible")
          ;
        }
      })
    /* END: MIDDLE AREA  */

    .trigger("floInit")
  ;

  /* START: SCROLL FOR MORE */
    $(dotb + "__footer-middle-area-scrolldown").on("click", function(){
      $('html, body').animate({
        scrollTop: $(window).height()
      }, 400);
    });
  /* END: SCROLL FOR MORE */

  /* START: MOBILE -> OVERFLOW BEHAVIOR */
    if ($(window).width() < 768) {
      var $content_wrap = $el.find(dotb + "__content-wrap");
      var b_freeze_class = b + "--mobile-freeze";

      $content_wrap.on("scroll", function(){

        // console.log(
        //   "Before Freeze:",
        //   $content_wrap.scrollTop() + $content_wrap.innerHeight(),
        //   $content_wrap[0].scrollHeight,
        //   $b.hasClass(b_freeze_class)
        // );

        if($content_wrap.scrollTop() + $content_wrap.innerHeight() >= $content_wrap[0].scrollHeight) {
          $b.addClass(b_freeze_class);
        }
      });

      $(document).on("scroll", function(){

        if ($b.hasClass(b_freeze_class)) {
          // console.log(
          //   "After Freeze:",
          //   $(document).scrollTop() + $(window).height(),
          //   $b.height() + $b.offset().top,
          //   $b.hasClass(b_freeze_class)
          // )

          if ($(document).scrollTop() + $(window).height() <= $b.height() + $b.offset().top) {
            $b.removeClass(b_freeze_class);
          }
        }
      });
    }
  /* END: MOBILE -> OVERFLOW BEHAVIOR */
}
