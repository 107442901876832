window.flo_block_slideshow_3 = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-block-slideshow-3";
  var dotb = "." + b;

  $el.find(dotb + "__slides")

    /* START: HEADER AREA */
      .on("elementsColorLight", function(){
        $el.find(".flo-header__logo")
          .addClass("flo-header__logo--is-light")
        ;
        setTimeout(function(){
          $el.find(".flo-header-mobile__logo")
            .addClass("flo-header-mobile__logo--light")
          ;  
        })
      })
      .on("elementsColorDark", function(){
        $el.find(".flo-header__logo")
          .removeClass("flo-header__logo--is-light")
        ;
        setTimeout(function(){
          $el.find(".flo-header-mobile__logo")
            .removeClass("flo-header-mobile__logo--light")
          ;  
        })
      })
    /* END: HEADER AREA */

    /* START: TITLE AREA  */
      .on("init afterChange", function(){
        var current_slide = $(this).find(".slick-current");

        var new_title = current_slide.attr("data-title");
        var new_subtitle = current_slide.attr("data-subtitle");
        var new_url = current_slide.attr("data-url");
        var new_link_label = current_slide.attr("data-link-label");

        var title_area__title = $el.find(dotb + "__title-area-title");
        var title_area__title_decorative_letter = $el.find(dotb + "__title-area-title-decorative-letter");
        var title_area__subtitle = $el.find(dotb + "__title-area-subtitle");
        var title_area__button = $el.find(dotb + "__title-area-button");

        if (new_title.trim() !== "") {
          title_area__title.fadeIn().changeText(new_title);
          title_area__title_decorative_letter.fadeIn().changeText(new_title[0]);
          title_area__subtitle.fadeIn().changeTextUI(new_subtitle, "fade", 800);
        } else {
          // title_area__title.fadeOut();
          // title_area__subtitle.fadeOut();
          // title_area__title_decorative_letter.fadeOut();
          title_area__title.changeText("");
          title_area__subtitle.changeText("");
          title_area__title_decorative_letter.changeText("");
        }

        if (new_url.trim() !== "" && new_link_label.trim() !== "") {
          title_area__button
            .removeClass("hidden")
            .addClass("visible")
            .attr("href", new_url)
            .text(new_link_label)
          ;
        } else {
          title_area__button
            .addClass("hidden")
            .removeClass("visible")
          ;
        }
      })
    /* END: TITLE AREA  */

    /* START: ARROWS */
      .on("init", function(){
        if ($el.find(dotb + "__slides .slick-slide:not(.slick-cloned)").length <= 1) {
          $el.find(dotb + "__arrows").remove();
        } else {
          $el.find(dotb + "__arrow--prev").on("click", function(){
            $el.find(dotb + "__slides").slick("slickPrev");
          });
          $el.find(dotb + "__arrow--next").on("click", function(){
            $el.find(dotb + "__slides").slick("slickNext");
          });
        }
      })
    /* END: ARROWS */

    .trigger("floInit")
  ;
  /* START: SCROLL FOR MORE */
    $(dotb + "__scroll-down-area").on("click", function(){
      $('html, body').animate({
        scrollTop: $el.height()
      }, 400);
    });
  /* END: SCROLL FOR MORE */
}
