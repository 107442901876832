window.flo_footer_copyrights_area_type_a = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-footer-copyrights-area-type-a";
  var dotb = "." + b;
  var parent = $el.parents(".flo-block");

  /* START: MENU - THIRD LEVEL DROPDOWN */
    if (window.outerWidth >= 768) {
      var dropdown_elements = new Foundation.DropdownMenu(
        $el.find(dotb + "__menu .menu-item-has-children ul")
      )
    }
  /* END: MENU - THIRD LEVEL DROPDOWN */

  /* START: MENU - MOBILE - ADD DROPDOWN TOGGLES TO EVERY ITEM WITH DROPDOWN */
    if (window.innerWidth < 768) {
      $el.find(dotb + "__menu > li.menu-item-has-children")
        .children("a")
        .after("<div class='"+b+"__menu-dropdown-toggle'><i class='flo-icon flo-icon-close'></i></div>")
      ;

      $el.find(dotb + "__menu").on("click", dotb + "__menu-dropdown-toggle", function(e){
        e.preventDefault();
        console.log("Toggle Clicked");
        $(this).siblings(".sub-menu").slideToggle("slow");
        $(this).parent().toggleClass("children-visible");
      });
    }
  /* END: MENU - MOBILE - ADD DROPDOWN TOGGLES TO EVERY ITEM WITH DROPDOWN */


}
