window.flo_block_slideshow_4 = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-block-slideshow-4";
  var dotb = "." + b;
  var $b = $el.find(dotb);

  $el.find(dotb + "__slides")

    /* START: ARROWS */
      .on("init", function(){
        $el.find(dotb + "__arrow--prev").on("click", function(){
          $el.find(dotb + "__slides").slick("slickPrev");
        });
        $el.find(dotb + "__arrow--next").on("click", function(){
          $el.find(dotb + "__slides").slick("slickNext");
        });
      })
    /* END: ARROWS */

    /* START: COUNT - SET COUNT */
      .on("init", function(){
        if($el.find(dotb + "__counter-count").length){
          var $this = $(this);
          var count = $this.find(".slick-slide:not(.slick-cloned)").length;
          $el.find(dotb + "__counter-count").html(
            count
          );
        }
      })
    /* END: COUNT - SET COUNT */

    /* START: COUNT - SET INDEX */
      .on("init afterChange", function(){
        if($el.find(dotb + "__counter-count").length){
          var $this = $(this);
          var index = parseInt($this.find(".slick-current").attr("data-slick-index"))+1;
          
          $el.find(dotb + "__counter-index").changeTextUI(
            index,
            "counter"
          );
        }
      })
    /* END: COUNT - SET INDEX */
  ;

  /* START: INITIALIZATION */
    if ($b.hasClass(b + "--layout-a")) {
      $el.find(dotb + "__slides")
        .trigger("floInit")
      ;
    } else if ($b.hasClass(b + "--layout-b")) {
      $el.find(dotb + "__slides")
        .trigger("floInit", {
          variableWidth: true,
          centerMode: true,
          responsive : [{
            breakpoint: 767,
            settings: {
              adaptiveHeight: true,
              variableWidth: false
            }
          }]
        })
      ;
    }
  /* END: INITIALIZATION */
}
