window.flo_block_contact_block_2 = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-block-contact-block-2";
  var dotb = "." + b;
  var $b = $el.find(dotb);
  setTimeout(function(){
    var color = $b.find('.flo-block-contact-block-2__title-section').css('color');
    var color = color.slice(4, -1);
    var rgb = color.split(',');
    var luma = 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]; // per ITU-R BT.709
    var is_color_bright = luma > 40;
    if (is_color_bright) {
      $b.find(".flo-header__logo").addClass("flo-header__logo--is-light")
    } else {
      $b.find(".flo-header__logo").removeClass("flo-header__logo--is-light")
    }
  }, 1);
}
