$(function() {
  $(".flo-core-style").each(function() {
    let template = $(this);
    let style = template.html();
    style = style.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
    $("head").append(style);
    template.remove();
  });
  let fadeInStyleTag = document.createElement("style");
  fadeInStyleTag.classList = "flo-core-fade-in";
  fadeInStyleTag.innerHTML = `
    body * {
    outline: solid transparent;
    } 
    body {
    opacity: 1!important;
    }`;
  $(fadeInStyleTag).appendTo("head");
});
