window.flo_block_listing_1 = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-block-listing-1";
  var dotb = "." + b;
  var parent = $el.parents(".flo-block");
  var $b = $el.find(dotb);

  function do_masonry(){
    $b.masonry({
      columnWidth: dotb + '__grid-sizer',
      percentPosition: true
    })
  }

  $el.find("img").on("load", () => {
    do_masonry();
  });
  
  setTimeout(() => {
    $b.imagesLoaded(() => {
      do_masonry();
    });
  }, 10);
  
  $(window).on('resize', () => {
    do_masonry();
  })

  do_masonry();
}
