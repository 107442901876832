$(function(){

  var flo_header__class = "flo-header";
  var $flo_header = $("." + flo_header__class);
  var $header = $flo_header.parents("header");

  /* START: STICKY HEADER */
    var is_not_sticky_class = "is-not-sticky";
    var header_sticky_class = "is-sticky";

    $(".flo-header").addClass(is_not_sticky_class);
    if (window.innerWidth > 768) {
      $("header.flo-header--sticky .flo-header").first()
      .on("sticky-start", function(){
        $(this).removeClass(is_not_sticky_class);
        $header.addClass(header_sticky_class);
      })
      .on("sticky-end", function(){
        $(this).addClass(is_not_sticky_class);
        $header.removeClass(header_sticky_class);
        setTimeout(function () {
          $(this).sticky("update");
        }, 600);
      })
      .sticky({
        zIndex: 9999,
        className: "is-sticky"
      });
    }
  /* END: STICKY HEADER*/

  /* Start: Dropdown */
    if (window.outerWidth >= 768) {
      var dropdown_elements = new Foundation.DropdownMenu(
        $(".menu-item-has-children ul")
      )
    }
  /* END: DROPDOWN */

  /* START: LOGO CENTER - SPLIT MENU IN HALF */
  if ($header.find(".flo-header__menu-donor").length){
    $header.find(".flo-header__menu-donor").each(function(){
      var
        $menu_donor = $(this),
        $this_header = $(this).parents('.flo-header'),
        $menu_donor_ul = $menu_donor.find("> div > div > ul"),
        $menu_donor_first_level = $menu_donor_ul.children("li"),
        $menu_left = $this_header.find(".flo-header__menu--left > div > ul"),
        $menu_right = $this_header.find(".flo-header__menu--right > div > ul"),
        $search_form = $this_header.find(".flo-header__menu-donor").find("div[class*='__search-wrap']"),
        $center_column = $menu_donor.parents('.flo-header').find('.flo-header__centered-logo-wrap');
      ;
      $menu_donor_first_level.each(function(index){
        var
          $item = $(this),
          length = $menu_donor_first_level.length
        ;
        if (index < length / 2) {
          $menu_left.append($item);
        }
        if (index >= length / 2) {
          $menu_right.append($item);
        }
        if($search_form.length){
          $menu_right.append($search_form);
        }
        if (index == length-1) {
          setTimeout(function(){
            $menu_donor.remove();
          })
        }
      });
    })
  }
  /* END: LOGO CENTER - SPLIT MENU IN HALF */

  /* START: SEARCH TRIGGER */
    $(".flo-header__search-trigger").click(function(){
      $(this).parents(".flo-header__search-wrap").find('.flo-header__search-form').toggleClass("search-visible");
    });
  /* END: SEARCH TRIGGER */

  /* START: ADD ZINDEX TO THE BLOCK WITH HEADER INSIDE */
    $(".flo-block > .flo-block__container > header").parents(".flo-block").css("z-index", "3");
  /* END: ADD ZINDEX TO THE BLOCK WITH HEADER INSIDE */

});
