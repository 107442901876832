window.flo_footer_area_1_type_d = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-footer-area-1-type-d";
  var dotb = "." + b;
  var parent = $el.parents(".flo-block");

  /* START: TESTIMONIALS SLIDER */
  setTimeout(function () {
    $el.find(dotb + "__testimonials").slick({
      arrows: false,
      dots: true,
      dotsClass: b + "__testimonials-dots",
      fade: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      adaptiveHeight: true
    });
  }, 10);

  /* END: TESTIMONIALS SLIDER */
}
