window.flo_footer_area_1_type_c = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-footer-area-1-type-c";
  var dotb = "." + b;
  var parent = $el.parents(".flo-block");

  /* START: NEWSLETTER SIGNUP */
    var form = $el.find(dotb + "__form");
  /* END: NEWSLETTER SIGNUP */
}
