window.flo_wp_content_with_pagination = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-wp-content-with-pagination";
  var dotb = "." + b;
  var parent = $el.parents(".flo-block");

  /* START: MAKE PAGINATION STICKY */
    if (window.innerWidth > 767) {
      $el.find(dotb + "__pagination").stick_in_parent({
        offset_top: 100
      });
    }
  /* END: MAKE PAGINATION STICKY */
}
