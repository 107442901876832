window.flo_mobile_menu = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-mobile-menu";
  var dotb = "." + b;

  var opened_class = "body--flo-mobile-menu-popup-opened";
  var closed_class = "body--flo-mobile-menu-popup-closed";

  /* START: OPEN POPUP */
    $(".flo-header-mobile__menu-trigger")
      .on("click", function(){
        $("body")
          .addClass(opened_class)
          .removeClass(closed_class)
        ;
      })
      // TODO: Remove line when popup is finished
      // .trigger("click")
    ;
    $(".flo-header-mobile__sticky-icon").click(function(){
      $(".flo-header-mobile__menu-trigger").trigger('click');
    });
  /* END: OPEN POPUP */

  /* START: CLOSE BUTTON */
    $el.find(dotb + "__close").on("click", function(){
      $("body")
        .removeClass(opened_class)
        .addClass(closed_class)
      ;
    });
  /* END: CLOSE BUTTON */

  /* START: TYPE A -> ADD DROPDOWN TOGGLES TO EVERY ITEM WITH DROPDOWN */
    if ($el.hasClass(b + "--type-a")) {
      $el.find(dotb + "__menu > li.menu-item-has-children > a")
        .append("<span class='flo-mobile-menu__menu-dropdown-toggle'></span>")
      ;

      $el.on("click", dotb + "__menu-dropdown-toggle", function(e){
        e.preventDefault();
        $(this).parents("li").toggleClass("children-visible");
        $(this).parent().siblings(".sub-menu").slideToggle("slow");
      });
    }
  /* END: TYPE A -> ADD DROPDOWN TOGGLES TO EVERY ITEM WITH DROPDOWN */

  /* START: TYPE B -> ADD DROPDOWN TOGGLES TO EVERY ITEM WITH DROPDOWN */
    if ($el.hasClass(b + "--type-b")) {
      $el.find(dotb + "__menu > li.menu-item-has-children > a")
        .append("<span class='flo-mobile-menu__menu-dropdown-toggle'><i class='flo-icon-arrow-right'></i></span>")
      ;

      $el.find(dotb + "__menu > li > ul")
        .append("<li class='flo-mobile-menu__menu-dropdown-toggle submenu'><i class='flo-icon-arrow-right'></i></li>")
      ;

      var dropdown_opened_class = "flo-mobile-menu--dropdown-opened";
      $el.on("click", dotb + "__menu-dropdown-toggle", function(e){
        e.preventDefault();

        $.each($el, function(index, value){
          var current_opened = $(this).find(".children-visible");
          var current_opened_items = $(current_opened).children('ul');
          if($(current_opened).length != 0){
            $(current_opened).removeClass('children-visible');
            $(current_opened_items).fadeOut('slow');
          }
        })
        if($(this).hasClass('submenu')){
          $el.removeClass(b + "--dropdown-opened");
        } else {
          $(this).parents("li").addClass("children-visible");
          $el.addClass(dropdown_opened_class);
          var $dropdown = $(this).parent().siblings("ul");
          $dropdown.fadeIn("slow");
        }
        
      });
    }
  /* END: TYPE B -> ADD DROPDOWN TOGGLES TO EVERY ITEM WITH DROPDOWN */
}
