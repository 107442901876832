// $(function(){
$(window).load(function(){

  /* START: SIDEBAR WORKS - ADAPTIVE SIDEBAR */
    if (
      window.innerWidth > 767
      && $(".flo_sidebar").length
    ) {
      var adaptive_sidebar = $("body").hasClass("flo-sidebar--is-adaptive");
      var sidebar_works_css = [];
      var page_wrap = $(".flo_page_wrap");
      var sidebar = $(".flo_sidebar");
      var sidebar_height = 0;
      var blocks = $(".flo-block");

      /* START: SIDEBAR */
        var top = 0;
        if (blocks.first().find(".flo-slideshow").length) {
          top = blocks.first().outerHeight(true);
        }

        var right = 0

        // sidebar.css({
        //   "position": "absolute",
        //   "top": top,
        //   "right": right
        // });

        sidebar_works_css.push([
          ".flo_sidebar {",
            "position: absolute;",
            "top: " + top / 16 + "rem;",
            sidebar.hasClass("flo_sidebar--on-left") ? "left: " + right / 16 + "rem;" : "right: " + right / 16 + "rem;",
            ,
          "}"
        ].join("\n"));

        sidebar.children().each(function(){
          sidebar_height += $(this).outerHeight(true);
        });
        sidebar_height += parseInt(sidebar.css("padding-top"), 10);
        sidebar_height += parseInt(sidebar.css("padding-bottom"), 10);
      /* END: SIDEBAR */

      /* START: BLOCKS */
        var blocks_height = 0;
        blocks.each(function(){
          var block = $(this);
          var sidebarWidthInPercent = sidebar.outerWidth(true) * 100 / page_wrap.width();

          if (
            !block.find(".flo-slideshow, .flo-footer, .flo-header").length
          ) {
            var shrink = true;

            // console.log(blocks_height, sidebar_height);
            if (
              adaptive_sidebar
              && blocks_height > sidebar_height
            ) {
              shrink = false;
            }

            // block.css({
            //   "width": "calc(100% - " + sidebarWidthInPercent + "%)",
            //   "overflow-x": "hidden"
            // });

            if (shrink) sidebar_works_css.push([
              ".flo-block--"+block.data("id")+"{",
                "width: calc(100% - " + sidebarWidthInPercent + "%);",
                sidebar.hasClass("flo_sidebar--on-left") ? "margin-left: auto;" : "",
                "overflow-x: hidden;",
              "}"
            ].join("\n"));

            blocks_height += block.outerHeight(true);
          }
        });
      /* END: BLOCKS */

      /* START: PAGE WRAP */
        var first_block_height = 0;
        if (blocks.first().find(".flo-slideshow").length) {
          first_block_height = blocks.first().outerHeight(true);
        }

        var blocks_on_the_side_height = 0;
        blocks.each(function(){
          if (
            !$(this).find(".flo-slideshow, .flo-footer").length
          ) {
            blocks_on_the_side_height += $(this).outerHeight(true);
          }
        });

        var footer_height = 0;
        if ($(".flo-footer").length) {
          footer_height = $(".flo-footer").outerHeight(true);
        }

        // var sidebar_height = sidebar.outerHeight(true);
        // console.log(sidebar_height);
        if (sidebar_height > blocks_on_the_side_height) {
          var page_wrap_height = first_block_height + sidebar_height + footer_height;
          // console.log(first_block_height, sidebar_height, footer_height);

          page_wrap.css({
            "height" : page_wrap_height / 16 + "rem"
          });

          // $(".flo-footer").css({
          //   "position": "absolute",
          //   "bottom": 0,
          //   "left": 0,
          //   "width": "100%"
          // })

          sidebar_works_css.push([
            ".flo-footer {",
              "position: absolute;",
              "bottom: 0;",
              "left: 0;",
              "width: 100%;",
            "}"
          ].join("\n"));
        }
      /* END: PAGE WRAP */

      /* START: APPEND CSS */
        $("head").append([
          "<style class='sidebar-works-css'>",
            "@media (min-width: 768px) {",
              sidebar_works_css.join("\n"),
            "}",
          "</style>"
        ].join("\n"));
      /* END: APPEND CSS */
    }
  /* END: SIDEBAR WORKS - ADAPTIVE SIDEBAR */

});
