window.flo_block_listing_2 = function(el){
  // "use strict";
  var $el = $(el);
  var b = "flo-block-listing-2";
  var dotb = "." + b;
  var parent = $el.parents(".flo-block");
  var $b = $el.find(dotb);


  function do_masonry() {
    $b.masonry({
      columnWidth: 1
    });
  }

  if ($b.hasClass(b + "--layout-square")) {
    function do_sizing() {
      var items = $el.find(dotb + "__featured-image");
      items.each(function(){
        var width = $(this).width();
        $(this).css("height", width);
      });
    }

    setTimeout(function () {
      do_sizing();
    }, 10);

    $(window).on("resize", do_sizing);
  }

  if ($b.hasClass(b + "--layout-masonry")) {
    do_masonry();
    $el.find("img").imagesLoaded(function(){
      setTimeout(function(){
        do_masonry();
      });
    })
  }
}
